import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import 'react-datepicker/dist/react-datepicker.css';
import { DispatchContext, StateContext } from '../../../App';
import { modalCloseHandler, pageDeleteHandler, publishLogClearHandler, publishStartHandler } from '../../data/actions';
import { MODAL_TYPES } from '../../data/reducers';
import { find } from 'lodash';
import useBuilderActions from '../useBuilderActions';
import TrackingUserIndicationPerPage from '../../../components/common/TrackingUserIndicationPerPage';
import usePages, { PAGE_STATUSES } from '../../pages/usePages';
import ConfirmBox, { ConfirmBoxInsideModal } from '../../../components/common/ConfirmBox';
import { useTrackingUsers } from '../../session/useTrackingUsers';
import useLockedState from '../../session/useLockedState';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '55%'
  },
  overlay: {
    zIndex: 9999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const PublishModal = ({ isOpen, context }) => {
  const { executeScript, moveDraftToProduction, moveDraftToProductionLoading } = useBuilderActions();
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { lockedState } = useLockedState();

  const { isReleasing, userPublished } = useTrackingUsers();

  const [confirmDelete, setConfirmDelete] = useState('');
  const { pageStatus, deletePage } = usePages(context);

  const website = find(state.websites, (w) => w.id === state.currentWebsite);
  const isCurrentSiteProduction = website ? website.isProduction : false;

  const currentState = state.history[state.history.length - 1];
  const pages = Object.values(currentState.pages) || [];
  const draftPages = pages.filter((p) => p.isDraft);

  const [tab, setTab] = useState('publish');
  const [selectedPages, setSelectedPages] = useState([]);

  const handlePublish = (website) => {
    publishStartHandler(dispatch);
    const project = website.isProduction ? 'website' : 'website-draft';
    executeScript('release', project, {});
    userPublished();
  };

  const handleClose = () => {
    publishLogClearHandler(dispatch);
    modalCloseHandler(dispatch, MODAL_TYPES.PUBLISH_WEBSITE);
  };

  const handleMoveDraftToProd = async (draftPages) => {
    await moveDraftToProduction(website.id, draftPages);
  };

  const handleClickSelectPage = (id) => {
    const index = selectedPages.findIndex((s) => s === id);
    if (index >= 0) {
      const _selectedPages = [...selectedPages];
      _selectedPages.splice(index, 1);
      setSelectedPages(_selectedPages);
    } else {
      setSelectedPages([...selectedPages, id]);
    }
  };

  const handleDeletePage = (id) => {
    deletePage(id, state.currentWebsite, () => pageDeleteHandler(dispatch, id));
  };

  useEffect(() => {
    const ignorePages = [];
    if (state.tracking?.users) {
      Object.keys(state.tracking?.users).forEach((userTrackingId) => {
        draftPages.forEach((p) => {
          if (state.tracking?.users[userTrackingId].route === `/builder/${p.id}` && userTrackingId !== state.session.sessionId) {
            ignorePages.push(p.id);
          }
        });
      });
    }

    const _selectedPages = draftPages.reduce((arr, p) => {
      if (!ignorePages.includes(p.id)) arr.push(p.id);
      return arr;
    }, []);
    setSelectedPages(_selectedPages);
  }, [state.tracking?.users]);

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false} appElement={document.getElementById('root') || undefined} contentLabel='Publish modal'>
      <div className='builder-flex builder-w-full builder-justify-end '>
        <i className='fal fa-times builder-text-xl builder-cursor-pointer builder-text-2xl' onClick={() => handleClose()} />
      </div>
      <div className='flex builder-flex-row'>
        <button className={`builder-px-4 builder-py-2 ${tab === 'publish' ? ' builder-border-t builder-border-x' : 'builder-border-b'}`} onClick={() => setTab('publish')}>
          Publish
        </button>
        <button className={`builder-px-4 builder-py-2 ${tab === 'draft' ? ' builder-border-t builder-border-x' : 'builder-border-b'}`} onClick={() => setTab('draft')}>
          Draft
        </button>
        <div className=' builder-flex-1 builder-border-b'></div>
      </div>
      <div className='builder-border-b builder-border-x builder-p-2 '>
        {tab === 'publish' && (
          <div>
            <div className='builder-w-full builder-mb-4'>
              <div style={{ height: 150 }} className='builder-flex builder-flex-col-reverse builder-overflow-auto builder-bg-gray-50 builder-p-2'>
                {!state.publish.output && <div className='builder-text-gray-400 builder-text-sm builder-h-full builder-flex builder-items-center builder-justify-center'>You will see the publish process info here.</div>}
                {state.publish.output && (
                  <div>
                    {state.publish.output.split('\n').map((item, i) => (
                      <p key={i}>{item}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {context.domainName && (
              <a href={isCurrentSiteProduction ? `https://${context.domainName}` : `https://draft.${context.domainName}`} target='_blank' rel='noreferrer' className='builder-text-blue-600 builder-cursor-pointer'>
                {isCurrentSiteProduction ? `https://${context.domainName}` : `https://draft.${context.domainName}`}
              </a>
            )}

            <div className='builder-flex builder-justify-start builder-mt-6'>
              <ButtonWithIndicator loading={false} disabled={isReleasing} onClick={() => handleClose()} className='builder-ml-0' text='Cancel' />

              <ButtonWithIndicator loading={isReleasing} disabled={isReleasing} onClick={() => handlePublish(website)} text='Publish' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
            </div>
          </div>
        )}

        {tab === 'draft' && (
          <div>
            <div className='builder-flex builder-flex-col builder-gap-2 builder-max-h-80 builder-overflow-y-scroll' style={{ minHeight: 150 }}>
              {draftPages.map((page, index) => {
                let userInPage = false;
                if (state.tracking?.users) {
                  Object.keys(state.tracking?.users).forEach((userTrackingId) => {
                    if (window.location.pathname === `/builder/${page.id}`) {
                      userInPage = lockedState;
                    } else if (state.tracking?.users[userTrackingId].route === `/builder/${page.id}` && userTrackingId !== state.session.sessionId) {
                      userInPage = true;
                    }
                  });
                }

                return (
                  <div key={index} className='builder-flex builder-flex-row builder-gap-2 builder-items-center'>
                    <input
                      type='checkbox'
                      disabled={userInPage}
                      checked={selectedPages.includes(page.id)}
                      onChange={() => handleClickSelectPage(page.id)}
                      className='builder-h-4 builder-w-4 builder-text-indigo-600 focus:builder-ring-indigo-500 builder-border builder-border-gray-300 builder-rounded-md'
                    />
                    <div>{page.url}</div>
                    <TrackingUserIndicationPerPage className='builder-justify-start' route={`/builder/${page.id}`} />
                    <button disabled={userInPage} className={`builder-flex builder-items-center ${userInPage ? 'builder-opacity-30' : 'builder-cursor-pointer hover:builder-text-primary'} `} onClick={() => setConfirmDelete(page.id)}>
                      <i className='fal fa-trash-alt builder-mr-1' style={{ fontSize: 12 }} /> {pageStatus === PAGE_STATUSES.DELETING ? 'Deleting' : 'Delete'}
                    </button>
                  </div>
                );
              })}
            </div>
            <div className='builder-flex builder-justify-start builder-mt-6'>
              <ButtonWithIndicator loading={false} disabled={moveDraftToProductionLoading} onClick={() => handleClose()} className='builder-ml-0' text='Cancel' />

              <ButtonWithIndicator
                loading={moveDraftToProductionLoading}
                disabled={moveDraftToProductionLoading}
                onClick={() => handleMoveDraftToProd(selectedPages)}
                text='Draft to prod'
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-blue-500'
              />
            </div>
            {confirmDelete !== '' && (
              <ConfirmBoxInsideModal
                title='Delete draft page'
                text='Are you sure you want to delete the draft page?'
                type='DANGER'
                onCancel={() => setConfirmDelete('')}
                onConfirm={() => {
                  handleDeletePage(confirmDelete);
                  setConfirmDelete('');
                }}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PublishModal;
